import _after from "./after";
import _ary from "./ary";
import _before from "./before";
import _bind from "./bind";
import _bindKey from "./bindKey";
import _curry from "./curry";
import _curryRight from "./curryRight";
import _debounce from "./debounce";
import _defer from "./defer";
import _delay from "./delay";
import _flip from "./flip";
import _memoize from "./memoize";
import _negate from "./negate";
import _once from "./once";
import _overArgs from "./overArgs";
import _partial from "./partial";
import _partialRight from "./partialRight";
import _rearg from "./rearg";
import _rest from "./rest";
import _spread from "./spread";
import _throttle from "./throttle";
import _unary from "./unary";
import _wrap from "./wrap";
var exports = {};
exports = {
  "after": _after,
  "ary": _ary,
  "before": _before,
  "bind": _bind,
  "bindKey": _bindKey,
  "curry": _curry,
  "curryRight": _curryRight,
  "debounce": _debounce,
  "defer": _defer,
  "delay": _delay,
  "flip": _flip,
  "memoize": _memoize,
  "negate": _negate,
  "once": _once,
  "overArgs": _overArgs,
  "partial": _partial,
  "partialRight": _partialRight,
  "rearg": _rearg,
  "rest": _rest,
  "spread": _spread,
  "throttle": _throttle,
  "unary": _unary,
  "wrap": _wrap
};
export default exports;
export const after = exports.after;